@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.multy {
  &--win {
    background-image: url("../../../images/royal/win-elipse.png");
    background-repeat: no-repeat;
    background-position: 0 0;

    @include mw($ph) {
      background-image: url("../../../images/how/how-bg-mob.png");
      background-size: contain;
      background-position: center -100%;
    }
  }
}
