@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.prizes {
  background-image: url("../../../images/prizes/prizes-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 10.5rem;
  border-bottom: torem(1) solid #f5ec5a;
  border-top: torem(1) solid #f5ec5a;

  @include mw($tb) {
    background-image: url("../../../images/prizes/prizes-bg-mob.png");
  }

  @include mw($ph) {
    padding-bottom: 3rem;
  }

  &__inner {
    padding-top: torem(111);

    @include mw($ph) {
      padding-top: 2.2rem;
    }
  }

  &__title {
    margin-bottom: 1.4rem;

    @include mw($tb) {
      margin-bottom: 0;
    }
  }

  &__slide {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &__swiper {
    // padding-bottom: 3rem;
  }
}

.marquee {
  background-image: url("../../../images/prizes/marquee-bg.png");
  background-size: 100% 100%;
  padding: 0.6rem 0;
  display: flex;
  justify-content: center;
  width: 70%;
  border-radius: 16px;
  margin-bottom: 3rem;
  box-shadow: 0px 0px torem(57) 0px rgba(228, 215, 0, 0.67);

  &__list {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
    width: 90%;
  }

  &__item {
    flex-shrink: 0;
    display: flex;
    gap: 20px;
    counter-reset: item;
    justify-content: space-around;
    min-width: 100%;
    animation: scroll 10s linear infinite;
    color: #f5ec5a;

    text-shadow: 0px 0px 15px rgba(255, 232, 27, 0.4);

    font-size: torem(24);
    font-style: normal;
    font-weight: 900;
    line-height: 175%;
    text-transform: uppercase;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: torem(57);
  flex-grow: 1;

  @include mw($ph) {
    padding-top: 3.4rem;
  }

  &__img {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    margin-bottom: auto;

    aspect-ratio: 1 / 1;
    font-style: 0;

    div {
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &--1 {
    .slide__img {
      width: 64%;

      img {
        height: 100%;
      }
    }
  }

  &--2 {
    .slide__img {
      width: 62%;

      img {
        height: 100%;
      }
    }
  }
  &--3 {
    .slide__img {
      width: 68%;

      div {
        align-items: center;
      }
    }

    img {
      // height: 100%;
    }
  }

  &__title {
    color: #fff;
    text-align: center;
    font-size: torem(40);
    font-style: normal;
    font-weight: 700;
    line-height: 110.081%;
    text-transform: uppercase;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mw($ph) {
      font-size: 1.6rem;
      margin-top: 2rem;
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
