@import "../../../sass/helpers/mixins";
@import "../../../sass/helpers/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;

  svg {
    transition: 0.5s;
  }

  &--primary {
    background-color: #f5ec5a;
    svg {
      stroke: #a70e13;
    }

    @include hocus() {
      background-color: #a70e13;
      svg {
        stroke: #f5ec5a;
      }
    }

    &:active {
      background-color: #f5ec5a;
      svg {
        stroke: #a70e13;
      }
    }
  }

  &--default {
    background-color: #fff;
    border: torem(1) solid #a70e13;
    svg {
      stroke: #a70e13;
    }

    @include hocus() {
      background-color: #a70e13;
      svg {
        stroke: #fff;
      }
    }

    &:active {
      background-color: #fff;
      svg {
        stroke: #a70e13;
      }
    }
  }

  &--lg {
    width: torem(80);
    height: torem(80);
    border-radius: torem(20);

    @include mw($tb) {
      width: torem(40);
      height: torem(40);
      border-radius: torem(8);
    }
  }

  &--md {
  }

  &--xs {
    width: torem(40);
    height: torem(40);
    border-radius: torem(8);
    svg {
      width: torem(28);
      height: torem(28);
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &--header {
    display: none;

    @include mw($tb) {
      display: flex;
    }
  }
}
