@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.faq {
  border-top: 1px solid #f5ec5a;
  border-bottom: 1px solid #f5ec5a;
  padding-top: 6rem;
  padding-bottom: torem(125);
  background-image: url("../../../images/faq/faq-bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  overflow: hidden;

  @include mw($tb) {
    padding-bottom: 3rem;
  }

  @include mw($ph) {
    padding-top: 3rem;
  }

  &__inner {
    padding-left: 15px;
    padding-right: 15px;
    width: 80%;
    margin: 0 auto;

    @include mw($tb) {
      width: auto;
      padding: 0;
    }
  }

  &__coin {
    height: 0;
    position: relative;
  }

  &__wrap {
    background-color: #fff;
    border-radius: torem(40);
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      display: block;
      aspect-ratio: 1 / 1.22;
      background-image: url("../../../images/faq/faq-coin.png");
      background-repeat: no-repeat;
      background-size: cover;
      top: 2rem;
      left: -7%;
      width: 10%;
      z-index: -1;

      @include mw($ph) {
        top: -4rem;
        left: 11%;
        width: 25%;
      }
    }

    @include mw($tb) {
      border-radius: torem(20);
    }

    @include mw($ph) {
      border-radius: 0;
      margin: 0;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 4rem;

    @include mw($ph) {
      margin-bottom: 2.5rem;
    }
  }
}
