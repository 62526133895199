@import "../../sass/helpers/variables";
@import "../../sass/helpers/mixins.scss";

.header {
  background-color: rgba(167, 14, 19, 0.01);
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #a70e13;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    padding-left: torem(100);
    padding-right: torem(100);
    border-bottom: torem(1) solid #f5ec5a;

    @include mw($ldt) {
      padding-left: 1rem;
      padding-right: 2rem;
    }

    @include mw($tb) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 1rem;
    }
  }

  &__burger {
  }
}

.nav {
  display: flex;
  column-gap: torem(30);

  @include mw($tb) {
    left: 0;
    top: 0;
    gap: 0;
  }
}
