@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.how {
  position: relative;
  &__cube {
    position: absolute;
    left: 2%;
    top: 4%;
    aspect-ratio: 1 / 1;
    width: 20%;

    @include mw($tb) {
      top: 13%;
      width: 29%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__candy {
    position: absolute;
    right: 0;
    top: 0;
    aspect-ratio: 1 / 1.44;
    width: 20%;
    display: none;

    @include mw($tb) {
      top: 11%;
      width: 32%;
    }

    div {
      display: contents;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__inner {
    padding-top: torem(118);
    background-image: url("../../../images/how/how-slide.png"),
      url("../../../images/how/how-slide.png"),
      url("../../../images/how/how-slide.png");
    background-position: 2% 50%, 50% 50%, 98% 50%;
    background-size: 32%;
    background-repeat: no-repeat;

    @include mw($tb) {
      padding-top: 3rem;
      background-image: url("../../../images/how/how-slide.png");
      background-size: 50%;
      background-position: 50% 30%;
    }
  }

  &__title {
    margin-bottom: 6rem;

    @include mw($tb) {
      margin-bottom: 4rem;
    }
  }

  &__swiper {
    margin-bottom: 2rem;
  }

  &__btn {
    margin: 0 auto;

    @include mw($tb) {
      display: none;
    }
  }
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    border: torem(5) solid #f5ec5a;
    width: 72%;
    position: relative;
    margin-bottom: 2.7rem;
    flex-shrink: 0;

    div {
      display: flex;
    }

    @include mw($tb) {
      width: 60%;
      margin-bottom: 1.7rem;
    }
  }

  &__num {
    border-radius: 50%;
    background-color: #fff;
    width: torem(50);
    height: torem(50);
    border: torem(2) solid #f5ec5a;
    outline: torem(2) solid #a70e13;
    outline-offset: -0.25rem;
    position: absolute;
    top: -0;
    right: 18%;
    z-index: 1;
    overflow: hidden;
    color: #a70e13;
    font-size: torem(34);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mw($tb) {
      width: 2.3rem;
      height: 2.3rem;
      font-size: 1.5rem;
      right: 14%;
      outline: none;
    }
  }

  &__title {
    color: #fff;
    font-size: torem(40);
    font-style: normal;
    font-weight: 700;
    line-height: 110.081%;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include mw($tb) {
      font-size: 1.7rem;
      margin-bottom: 0.5rem;
    }
  }

  &__text {
    color: #fff;
    font-size: torem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 116.667%;
    text-align: center;
    max-width: 68%;

    @include mw($tb) {
      font-size: 1rem;
    }
  }

  &--1 {
    .slide__img {
      img {
        transform: scale(1.2);
      }
    }
  }
}
