@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__block {
    width: 100%;

    &--half {
      display: flex;
      column-gap: torem(24);

      @include mw($ph) {
        flex-direction: column;
        column-gap: 0;
        row-gap: 1.7rem;
      }
    }

    &--checkbox {
      display: flex;
      flex-direction: column;
      row-gap: torem(8);
      align-items: flex-start;
    }
  }

  &__button {
    background-color: #a70e13;
    border: torem(1) solid #a70e13;
    border-radius: torem(20);
    padding: 1.7rem torem(20);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    display: block;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    @include mw($ph) {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }

    @include hocus() {
      background-color: #fff;
      color: #a70e13;
    }

    &:active {
      background-color: #a70e13;
      color: #fff;
    }

    &--default {
      background-color: #fff;
      color: #a70e13;

      @include hocus() {
        background-color: #a70e13;
        color: #fff;
      }

      &:active {
        background-color: #fff;
        color: #a70e13;
      }
    }

    &:disabled {
      background-color: #fff;
      color: #a70e13;
      opacity: 0.3;
      cursor: auto;
    }
  }

  &__err {
    color: #a70e13;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: torem(12);
  }
}

.input-block {
  width: 100%;

  &__label {
    display: block;
    width: 100%;
    position: relative;
  }

  &__name {
    position: absolute;
    color: #a2a2a2;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 torem(6);
    background-color: #fff;
    top: 0;
    left: torem(18);
    transform: translateY(-50%);

    @include mw($tb) {
      left: 0.8rem;
    }
  }

  &__input {
    width: 100%;
    padding: 1.7rem torem(24);
    border-radius: torem(20);
    border: torem(1) solid #c0c0c0;
    background: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    transition: 0.3s;
    color: #111;

    &--eye {
      padding-right: 3.5rem;
    }

    @include mw($tb) {
      font-size: 1rem;
      padding: 1.5rem 1.1rem;

      &--eye {
        padding-right: 3.5rem;
      }
    }

    &::placeholder {
      color: #a2a2a2;
    }

    &:hover {
      border-color: #a70e13;
    }

    &:focus {
      border-color: #a70e13;
      box-shadow: 0px 0px 0px torem(4) rgba(253, 228, 4, 0.24);
    }

    &--error {
      border-color: #ff4e4e;
      box-shadow: 0px 0px 0px 4px rgba(255, 78, 78, 0.32);
    }
  }

  &__textarea {
    resize: none;
    aspect-ratio: 5.5 / 1;

    @include mw($ph) {
      aspect-ratio: 2.4 / 1;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__coundown {
    margin-top: torem(12);
    color: #a70e13;
    font-size: torem(16);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__send {
    color: #a70e13;
    font-size: torem(16);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;

    @include hocus() {
      opacity: 0.7;
    }
  }

  &__info {
    text-align: center;
    color: #111;
    font-size: torem(20);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__error {
    color: #a70e13;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: torem(12);
  }

  &__eye {
    background-color: transparent;
    aspect-ratio: 1;
    width: torem(24);
    position: absolute;
    @include centered("y");
    right: 1.5rem;
    overflow: hidden;
    cursor: pointer;

    svg {
      position: absolute;
      @include centered();
      width: 100%;
      height: 100%;
      stroke: #a70e13;
      transition: 0.3s;
    }

    @include hocus() {
      svg {
        opacity: 0.6;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: flex-start;
  width: 100%;
  &__input {
    @include vh();

    &:checked + .checkbox__indicator {
      background-color: #a70e13;
      border-color: #a70e13;
    }
    &:checked + .checkbox__indicator svg {
      stroke: #fff;
    }
  }

  &__indicator {
    aspect-ratio: 1;
    width: torem(22);
    background-color: #fff;
    border-radius: torem(8);
    margin-right: torem(16);
    flex-shrink: 0;
    transition: 0.3s;
    overflow: hidden;
    position: relative;
    border: torem(1) solid #000;

    svg {
      position: absolute;
      width: torem(20);
      height: torem(20);
      @include centered();
      stroke: #fff;
      transition: 0.3s;
    }
  }

  &__label {
    color: #111;
    font-size: torem(16);
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    padding: torem(2) 0;

    @include mw($tb) {
      padding: 0;
    }
  }
}

.input-file {
  aspect-ratio: 1.9 / 1;
  border-radius: 1rem;
  border: torem(1) dashed #a70e13;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &__input {
    @include vh();
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: torem(21);

    svg {
      stroke: #a70e13;
      aspect-ratio: 1;
      width: torem(64);
    }
  }

  &__title {
    color: #111;
    text-align: center;
    font-size: torem(20);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.revizor_container {
}

.revizor__button-style {
  background-color: #a70e13;
  transition: 0.3s;
  aspect-ratio: 5 / 1;
  border: torem(1) solid #a70e13;
  font-size: torem(20);
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;

  @include hocus() {
    background-color: #fff;
    color: #a70e13;
    border-color: #a70e13;
  }

  // aspect-ratio: 1.9 / 1;
  // border-radius: 1rem;
  // border: torem(1) dashed #a70e13;
  // background-color: #fff;
  width: 100%;
  height: 4.5rem;
}

#revizor-button-send {
  display: flex;
  align-items: center;
  justify-content: center;
}

.revizor__uploader-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.revizor__uploaded-files {
  margin: 0;
}

.revizor__uploader-button {
  font-size: torem(20);
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.ozon {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  &__block {
  }

  &__subtitle {
    color: #111;
    font-size: torem(20);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }

  &__text {
    color: #111;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;

  &__title {
    color: #a2a2a2;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 70%;
  }

  &__label {
    display: flex;
    align-items: center;
    width: 50%;
  }

  &__input {
    @include vh();
    &:checked + .radio__indicator {
      border: torem(6) solid #a70e13;
    }
  }

  &__indicator {
    aspect-ratio: 1;
    width: torem(24);
    border-radius: 50%;
    border: torem(1) solid #a70e13;
    background: #fff;
    order: -1;
    // transition: 0.3s;
  }

  &__name {
    color: #111;
    margin-left: torem(8);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.link-doc {
  color: #a70e13;
  transition: 0.3s;
  @include hocus() {
    opacity: 0.6;
  }
}
