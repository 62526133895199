@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(#000, 0.3);
  z-index: 15;
  display: flex;
  justify-content: center;
  // align-items: center;
  transition: 0.3s;
  padding: 1rem;
  overflow-y: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a70e13;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f5ec5a;
  }

  &__inner {
    width: 32%;
    min-width: 628px;
    padding: 4rem 3rem;
    background-color: #fff;
    border-radius: 2rem;
    position: relative;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;

    &--quiz {
      aspect-ratio: 1.8 / 1;
      width: 54%;
      background-color: #a70e13;
      border: torem(1) solid #fff;
      padding: torem(80);
      background-image: url("../../../images/quiz/quiz-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mw($ldt) {
        width: 70%;
      }

      @include mw($dt) {
        width: 80%;
        padding: 4rem 3rem;
      }

      @include mw($tb) {
        aspect-ratio: auto;
      }

      @include mw($ph) {
        padding: 3rem 1rem;
      }
    }

    @include mw($tb) {
      min-width: auto;
      width: 100%;
    }

    @include mw($ph) {
      padding: 4rem 1rem 2rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    &--big {
      row-gap: torem(20);
      // row-gap: torem(50);
    }
  }

  &__close {
    background-color: transparent;
    aspect-ratio: 1;
    width: torem(24);
    position: absolute;
    top: torem(24);
    right: torem(24);
    cursor: pointer;
    transition: 0.3s;

    @include hocus() {
      opacity: 0.5;
    }

    svg {
      width: 100%;
      height: 100%;
      stroke: #111;
    }

    &--quiz {
      svg {
        stroke: #fff;
      }
    }
  }

  &__title {
    color: #111;
    text-align: center;
    font-size: torem(40);
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  &__button {
    width: 100%;
  }

  &__forget {
    cursor: pointer;
    transition: 0.3s;
    background-color: transparent;

    @include hocus() {
      opacity: 0.7;
    }
  }

  &__not {
    color: #111;
    font-size: torem(24);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
    align-self: center;
    border-bottom: torem(1) solid rgba(0, 0, 0, 0.48);
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;

    @include hocus() {
      opacity: 0.7;
    }
  }
}

.rule {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  &__title {
    color: #111;
    font-size: torem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 116.667%;
    text-align: center;
  }

  &__num {
    min-width: 1.1rem;
  }

  &__item {
    color: #5b5959;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  }
}

.quiz {
  position: relative;

  @include mw($tb) {
    display: flex;
    flex-direction: column;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: torem(60);

    &--fail {
      align-items: center;
    }

    @include mw($tb) {
      align-items: center;
      row-gap: 2rem;
    }
  }
  &__title {
    justify-content: flex-start;
  }

  &__text {
    color: #fff;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 131.25%;
    max-width: 50%;

    &--fail {
      text-align: center;
      max-width: 70%;
    }

    @include mw($tb) {
      max-width: none;
      text-align: center;
    }
  }

  &__rule {
    color: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration-line: underline;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 1.5rem;

    @include mw($tb) {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    @include hocus() {
      opacity: 0.6;
    }
  }

  &__maskot {
    position: absolute;
    width: 80%;
    aspect-ratio: 1;
    bottom: -44%;
    right: -22%;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    &--dance1 {
      bottom: -50%;
      right: -10%;
      width: 67%;

      @include mw($dt) {
        bottom: -50%;
        right: -5%;
        width: 50%;
      }
    }

    &--dance2 {
      bottom: -50%;
      right: -10%;
      width: 67%;
      @include mw($dt) {
        bottom: -50%;
        right: -5%;
        width: 50%;
      }
    }

    &--dance3 {
      bottom: -50%;
      right: -10%;
      width: 67%;
      @include mw($dt) {
        bottom: -50%;
        right: -5%;
        width: 50%;
      }
    }

    &--ozon500 {
      bottom: -30%;
      right: -10%;
      width: 67%;
    }

    &--ozon1000 {
      bottom: -49%;
      right: -14%;
      width: 80%;
    }

    &--ozon3000 {
      bottom: -49%;
      right: -14%;
      width: 80%;
    }

    &--tg {
      bottom: -42%;
      right: -5%;
      width: 67%;

      @include mw($dt) {
        right: 0;
      }
    }

    div {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include mw($tb) {
      position: relative;
      // display: none;
      align-self: center;
      right: auto;
    }

    @include mw($ph) {
      width: 100%;
    }
  }
}

.copy {
  display: block;
  width: auto;
  cursor: auto;
  position: relative;

  @include mw($ph) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__input {
    display: block;
    color: #a70e13;
    text-align: center;
    font-size: 2rem;
    font-style: italic;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    border-radius: 2.625rem;
    background-color: #f5ec5a;
    box-shadow: 0px 0px 0px 0.5rem #f5ec5a, 0px 0px 0px 0.25rem #a70e13,
      0px 2.5rem 3.125rem 0px rgba(158, 0, 0, 0.37);
    padding: 1.5rem 1.9rem;
    outline: 0.25rem solid #a70e13;
    border: none;
    width: 100%;
    @include vh();
  }

  &__link {
    padding-right: 4rem;
    text-transform: none;

    @include mw($ph) {
      display: block;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 3.5rem;
    }
  }

  &__button {
    position: absolute;
    @include centered("y");
    background-color: transparent;
    cursor: pointer;
    right: 2rem;
    pointer-events: none;

    @include mw($ph) {
      right: 1.5rem;
    }
  }

  svg {
    width: torem(24);
    height: torem(24);
    pointer-events: none;
    stroke: #a70e13;
  }
}
