@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.royal {
  padding-bottom: 6rem;

  @include mw($ph) {
    margin-bottom: 3.5rem;
  }
  &__inner {
  }

  &__title {
    margin-bottom: -4rem;
    position: relative;
    z-index: 1;

    @include mw($tb) {
      margin-bottom: -2rem;
    }
  }

  &__content {
    aspect-ratio: 2.76 / 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 93%;
    margin: 0 auto;
    border-radius: 5rem;
    box-shadow: 0px 0px 82px 101px rgba(0, 0, 0, 0.25) inset,
      0px 4px 32px 0px rgba(0, 0, 0, 0.25);

    @include mw($ldt) {
      border-radius: 4.5rem;
    }

    @include mw($dt) {
      border-radius: 4.5rem;
    }

    @include mw($tb) {
      aspect-ratio: 1.56 / 1;
      box-shadow: 0px 0px 21px 26px rgba(0, 0, 0, 0.25) inset;
      width: 100%;
      border-radius: 2.2rem;
    }

    @include mw($ph) {
      border-radius: 1.5rem;
    }
  }

  &__wrap {
    width: 95%;
    height: 84%;
    display: flex;
    padding-left: 7%;
    align-items: flex-end;
    column-gap: 8%;

    @include mw($ldt) {
      column-gap: 5%;
    }

    @include mw($dt) {
      column-gap: 2rem;
    }

    @include mw($tb) {
      align-items: flex-start;
      flex-direction: column-reverse;
      column-gap: 0;
      row-gap: 2rem;
      height: 88%;
    }

    @include mw($ph) {
      row-gap: 1.5rem;
    }
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-size: 100% 100%;
    border-radius: inherit;
    overflow: hidden;

    img {
      display: block;
      // width: 100%;
      // height: 100%;
      // object-fit: contain;
    }

    div {
      display: flex;
    }

    picture {
      display: contents;
    }
  }

  &__coin {
    position: absolute;
    bottom: -18%;
    right: 35%;
    aspect-ratio: 1.2 /1;
    width: 15%;

    @include mw($tb) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__maskot {
    position: absolute;
    bottom: -22%;
    right: 1%;
    aspect-ratio: 1.11 / 1;
    width: 47%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include mw($tb) {
      bottom: -18%;
      width: 64%;
    }
  }

  &__phone {
    aspect-ratio: 1 / 1.1;
    width: 25%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @include mw($ph) {
      aspect-ratio: 1 / 0.9;
      overflow: hidden;
    }

    div {
      flex-grow: 1;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    @include mw($tb) {
    }
  }

  &__prize {
    align-self: center;

    @include mw($tb) {
      align-self: auto;
    }
  }
}

.prize {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;

  @include mw($ldt) {
    width: 32%;
  }

  @include mw($dt) {
    width: 35%;
  }

  @include mw($tb) {
    width: 58%;
  }

  &__title {
    color: #fff;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 131.25%;
    margin-bottom: 2.8rem;

    &--empty {
      margin-bottom: 0;
    }

    @include mw($tb) {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    @include mw($ph) {
      font-size: 1rem;
    }
  }

  &__name {
    color: #a70e13;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    padding: torem(4) torem(12);
    border-radius: 2rem;
    background-color: #fff;
    margin-bottom: torem(20);

    @include mw($ph) {
      font-size: 1rem;
      padding: 2px 4px;
      margin-bottom: 4px;
    }
  }

  &__tel {
    color: #fff;

    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    @include mw($ph) {
      font-size: 1rem;
    }
  }
}
