// colors
$black: #000000;
$white: #ffffff;
$bg: $white;

// sizes;
$width-dt: 1525px;
$width-tablet: 361px;
$width-phone: 1100px;
$gap: 15px;
$gap-tablet: 20px;
$gap-phone: 20px;

// breackpoints;
$min-ph: 360px;
$ph: 600px;
$tb: 768px;
$empty: 1000px;
$dt: 1200px;
$ldt: 1440px;

// other;
$text-dark: #202020;
$green: #718753;
$green-two: #405a26;
$grey: #7a838d;

//
$delay: 0.3s;
