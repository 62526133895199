@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.step {
  padding-top: 2rem;
  border-bottom: torem(1) solid #f5ec5a;
  padding-bottom: 5rem;
  overflow: hidden;

  @include mw($tb) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__inner {
    display: flex;
    position: relative;
    padding-left: 9.3%;
    padding-right: 9.5%;

    @include mw($ldt) {
      padding-left: 0;
    }

    @include mw($tb) {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
  }

  &__coin {
    position: absolute;
    aspect-ratio: 1 / 1;
    left: 9%;
    top: -3rem;

    @include mw($tb) {
      width: 24%;
      left: 1%;
      top: -1rem;
    }
  }

  &__left {
    width: 46%;
    padding-top: 7.5rem;
    padding-left: 5%;

    @include mw($tb) {
      width: 100%;
      padding-left: 0;
      padding-top: 1.6rem;
    }
  }

  &__right {
    width: 54%;

    @include mw($tb) {
      width: 100%;
    }
  }

  &__title {
    justify-content: flex-start;
    margin-bottom: 4.1rem;

    @include mw($tb) {
      justify-content: center;
      margin-bottom: 2.8rem;
    }
  }

  &__btn {
    @include mw($tb) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.text {
  color: #fff;
  font-size: torem(32);
  font-style: normal;
  font-weight: 700;
  line-height: 131.25%;
  margin-bottom: 4.5rem;

  @include mw($tb) {
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 2.5rem;
  }

  &__number {
    padding: 0 torem(4);
    border-radius: torem(12);
    color: #a70e13;
    background-color: #fff;
    // margin: 0 torem(4);
  }

  &__caps {
    text-transform: uppercase;
  }
}

.machine {
  position: relative;

  &__inner {
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;

    @include mw($tb) {
      aspect-ratio: 1 / 1.13;
    }
  }

  &__bg {
    position: absolute;
    left: 8%;
    top: 5%;
    width: 72%;
    aspect-ratio: 1;
    z-index: 1;
    pointer-events: none;

    @include mw($tb) {
      left: 0;
      top: 12%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    position: absolute;
    right: -8%;
    top: 15%;
    padding: 2rem 2rem;
    background-image: url("../../../images/step/step-bubble.png");
    background-size: 100% 100%;
    aspect-ratio: 2.7 / 1;
    width: 43%;
    z-index: 3;
    display: flex;
    align-items: center;

    @include mw($tb) {
      right: 0;
      padding: 1rem 1.5rem;
    }

    @include mw($ph) {
      right: 0;
      padding: 0.7rem 1rem;
    }
  }

  &__text {
    color: #a70e13;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    // max-height: 110%;
    // overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 0.1rem;
    }

    @include mw($tb) {
      font-size: 1rem;
    }

    @include mw($ph) {
      font-size: 0.75rem;
    }
  }

  &__balls {
    position: absolute;
    width: torem(36);
    height: torem(39);
    background-image: url(../../../images/step/step-balls.png);
    background-size: contain;
    background-repeat: no-repeat;
    left: -5%;
    bottom: -14%;

    @include mw($tb) {
      width: 1.5rem;
      height: 1.5rem;
      left: -9%;
      bottom: -24%;
    }
  }

  &__slot {
    position: absolute;
    right: 20%;
    bottom: 25%;
    z-index: 2;
  }

  &__content {
    position: absolute;
    width: 48%;
    z-index: 1;
    bottom: 25%;
    right: 21%;

    @include mw($tb) {
      bottom: 23%;
      right: 29%;
    }
  }

  &__game {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__control {
    position: absolute;
    aspect-ratio: 1.49 / 1;
    width: 90%;
    bottom: 3%;
    left: 13%;
    z-index: 2;

    @include mw($tb) {
      left: 5%;
    }
  }

  &__count {
    position: absolute;
    bottom: 2%;
    right: 36%;
    color: #fff;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: torem(26);
  }
}

.go {
  position: absolute;
  width: 10%;
  aspect-ratio: 1 / 4.5;
  bottom: 20%;
  right: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  z-index: -1;

  &__img {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
    &--top {
      aspect-ratio: 1 / 2.3;
      width: 90%;
      background-image: url("../../../images/step/top.png");
    }
    &--middle {
      aspect-ratio: 1.4 / 1;
      width: 100%;
      align-self: flex-end;
      background-image: url("../../../images/step/middle.png");
      transform: translateX(20%);
    }
    &--down {
      aspect-ratio: 1 / 2.3;
      width: 90%;
      background-image: url("../../../images/step/down.png");
    }
  }
}
.show {
  opacity: 1;
}

.slt {
  aspect-ratio: 2 / 1;
  background-color: #fff;

  &__swiper {
    height: 100%;
  }
}

.slot {
  &__item {
    display: flex !important;
    justify-content: center;
    background-color: #fff6dd;
  }

  &__img {
    width: 33%;
    flex-shrink: 0;
    // background-image: url("../../../images/step/mas.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: 0.1s;

    &[data-img="dance"] {
      background-image: url("../../../images/step/dance.png");
    }

    &[data-img="sticker"] {
      background-image: url("../../../images/step/sticker.png");
    }

    &[data-img="ozon500"] {
      background-image: url("../../../images/step/ozon500.png");
    }

    &[data-img="ozon1000"] {
      background-image: url("../../../images/step/ozon1000.png");
    }

    &[data-img="ozon3000"] {
      background-image: url("../../../images/step/ozon3000.png");
    }

    &[data-img="danceball"] {
      background-image: url("../../../images/step/danceball.png");
    }
  }
}
