.slt {
  background-color: #fff6dd;
  .slot__img {
    transform: scale(0.5);
  }

  &-active {
    .slot__img:first-child {
      transform: scale(0.5) rotate(10deg) translateX(10%) rotateX(30deg);
    }

    .slot__img:nth-child(2) {
      transform: scale(0.5) rotateX(30deg);
    }

    .slot__img:last-child {
      transform: scale(0.5) rotate(-10deg) translateX(-10%) rotateX(30deg);
    }
  }
  &-prev {
  }
  &-next {
    .slot__img {
      transform: scale(1);
    }

    & + .swiper-slide {
      .slot__img:first-child {
        transform: scale(0.5) rotate(-10deg) translateX(10%) rotateX(-30deg);
      }
      .slot__img:nth-child(2) {
        transform: scale(0.5) rotateX(-30deg);
      }

      .slot__img:last-child {
        transform: scale(0.5) rotate(10deg) translateX(-10%) rotateX(-30deg);
      }
    }
  }
  // &-active {
  //   .slot__img {
  //     transform: scale(1);
  //   }
  // }
  // &-prev {
  //   .slot__img:first-child {
  //     transform: scale(0.5) rotate(10deg) translateX(10%) rotateX(30deg);
  //   }

  //   .slot__img:nth-child(2) {
  //     transform: scale(0.5) rotateX(30deg);
  //   }

  //   .slot__img:last-child {
  //     transform: scale(0.5) rotate(-10deg) translateX(-10%) rotateX(30deg);
  //   }
  // }
  // &-next {
  //   .slot__img:first-child {
  //     transform: scale(0.5) rotate(-10deg) translateX(10%) rotateX(-30deg);
  //   }
  //   .slot__img:nth-child(2) {
  //     transform: scale(0.5) rotateX(-30deg);
  //   }

  //   .slot__img:last-child {
  //     transform: scale(0.5) rotate(10deg) translateX(-10%) rotateX(-30deg);
  //   }
  // }
}
