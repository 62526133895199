@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.feedback {
  padding-top: 8.4rem;
  padding-bottom: torem(176);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../images/feedback/feedback-bg.png");
  background-position: center bottom;

  @include mw($tb) {
    background-image: url("../../../images/feedback/feedback-bg-mob.png");
    padding-top: 3.2rem;
    padding-bottom: 8rem;
  }

  &__inner {
    width: 80%;
    margin: 0 auto;
    position: relative;

    @include mw($tb) {
      width: auto;
    }
  }

  &__maskot {
    position: absolute;
    aspect-ratio: 1.67 / 1;
    width: 20%;
    bottom: -12rem;
    left: -7%;
    @include mw($tb) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 5.3rem;

    @include mw($tb) {
      margin-bottom: 2.6rem;
    }
  }

  &__text {
    color: #fff;
    font-size: torem(40);
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 4rem;
    text-align: center;

    @include mw($tb) {
      font-size: 1.5rem;
    }
  }
  &__form {
    background-color: #fff;
    border-radius: torem(40);
    padding: torem(57) torem(47);
    display: flex;
    flex-direction: column;
    row-gap: torem(24);
    @include mw($tb) {
      padding: 2rem 1rem;
      row-gap: 1.7rem;
    }

    @include mw($ph) {
      border-radius: 0;
    }
  }

  &__content {
    width: 65%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @include mw($tb) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mw($ph) {
      padding: 0;
      border-radius: 0;
    }
  }

  &__chock {
    position: absolute;
    aspect-ratio: 1 / 1.14;
    width: 57%;
    right: -34%;
    top: -5rem;
    z-index: -1;

    @include mw($tb) {
      display: none;
    }
  }

  &__btn {
    width: 100%;
  }
}
