@include font-load(Caldina, Regular, 400, normal);
@include font-load(Caldina, SemiBold, 600, normal);

@include font-load(ProximaNova, Regular, 400, normal);
@include font-load(ProximaNova, Semibold, 600, normal);

@include font-load(ProximaNova, Black, 900, normal);

@include font-load(ProximaNova, Bold, 700, normal);
@include font-load(ProximaNova, BoldIt, 700, italic);

@include font-load(ProximaNova, Extrabld, 800, normal);
@include font-load(ProximaNova, ExtrabldIt, 800, italic);

@include font-load(Verdana, Bold, 700, normal);
