@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.myprizes {
  padding-top: 7.5rem;
  padding-bottom: 6rem;

  @include mw($ph) {
    padding-top: 2rem;
  }

  &__inner {
    padding: 0 1.5rem;

    @include mw($dt) {
      width: 90%;
    }

    @include mw($ph) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__title {
    margin-bottom: 4.5rem;

    @include mw($ph) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16%;
    row-gap: 2rem;

    @include mw($ldt) {
      column-gap: 1rem;
    }

    @include mw($tb) {
      display: flex;
      flex-direction: column;
    }

    @include mw($ph) {
      row-gap: 2.5rem;
      column-gap: 0;
    }
  }
}

.card {
  &__inner {
    display: flex;
    align-items: center;
    column-gap: torem(24);

    @include mw($ph) {
      column-gap: 1.2rem;
    }
  }

  &__img-wrap {
    flex-shrink: 0;
    aspect-ratio: 1.6 / 1;
    border-radius: torem(8);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: torem(1) solid rgba(255, 255, 255, 0.32);
    box-shadow: 0px 41px 94px 0px rgba(128, 13, 17, 0.32);
    width: 32%;

    @include mw($dt) {
      width: 40%;
    }

    @include mw($ph) {
      width: 38%;
    }

    div {
      flex-grow: 1;
      display: flex;
    }

    picture {
      display: contents;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    // row-gap: 1rem;
    align-items: flex-start;

    @include mw($dt) {
      row-gap: 0.3rem;
    }

    @include mw($ph) {
      row-gap: 0.6rem;
    }
  }

  &__title {
    color: #fff;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;

    @include mw($ph) {
      font-size: 1.1rem;
    }
  }

  &__link {
    color: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration-line: underline;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;

    @include hocus() {
      opacity: 0.7;
    }

    @include mw($ph) {
      font-size: 1.1rem;
    }
  }
}

.copy {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 0.5rem;
  // min-width: 40%;
  margin-top: 0.3rem;

  @include mw($tb) {
    // min-width: 50%;
  }

  @include mw($ph) {
    // min-width: auto;
    // width: 100%;
  }

  &__button {
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;

    @include hocus() {
      opacity: 0.6;
    }
  }

  svg {
    aspect-ratio: 1;
    width: torem(24);
    pointer-events: none;
    stroke: #fff;
  }

  &__input {
    background-color: transparent;
    padding: 0;
    border: none;
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hidden {
      @include vh();
    }

    &:focus-visible {
      outline: none;
    }

    &::selection {
      background: transparent;
    }

    // min-width: 1rem;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: torem(87);
  margin-left: auto;
  margin-right: auto;

  &__text {
    color: #fff;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: torem(42);
  }
}
