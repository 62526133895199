@import "../../../sass/helpers/mixins.scss";
@import "../../../sass/helpers/variables.scss";

.link {
  color: #fff;
  font-size: torem(20);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  padding: torem(4) 0;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    bottom: torem(0);
    width: 100%;
    background-color: transparent;
    height: torem(1);
    left: 0;
    transition: 0.5s;
  }

  @include hocus() {
    color: #f5ec5a;

    &::after {
      background-color: #f5ec5a;
    }
  }

  &:active {
    &::after {
      background-color: transparent;
    }
  }

  @include mw($dt) {
    font-size: torem(18);
  }
}
