@import "../../../sass/helpers/mixins";
@import "../../../sass/helpers/variables";

.button {
  display: block;
  color: #a70e13;
  text-align: center;
  font-size: torem(32);
  font-style: italic;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  border-radius: torem(42);
  background-color: #f5ec5a;
  box-shadow: 0px 0px 0px torem(8) #f5ec5a, 0px 0px 0px torem(4) #a70e13,
    0px torem(40) torem(50) 0px rgba(158, 0, 0, 0.37);
  padding: 1.5rem 1.9rem;
  outline: torem(4) solid #a70e13;
  transition: 0.5s;
  cursor: pointer;

  @include mw($tb) {
    outline: none;
    border: torem(4) solid #a70e13;
  }

  @include mw($ph) {
    padding: 1.3rem 1.9rem;
    box-shadow: 0px 0px 0px 0.2rem #f5ec5a, 0px 0px 0px 0.1rem #a70e13,
      0px torem(40) torem(50) 0px rgba(158, 0, 0, 0.37);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include hocus() {
    background-color: #fff;
  }

  &:active {
    color: #fff;
    background-color: transparent;
  }

  @include mw($dt) {
    font-size: torem(28);
  }

  &--header--mob {
    display: none;
  }

  &--header {
    @include mw($tb) {
      display: none;

      &--mob {
        display: block;
        margin-top: 3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
