@import "../../../sass/helpers/mixins.scss";
@import "../../../sass/helpers/variables";

.logo {
  display: block;
  aspect-ratio: 3 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &--header {
    width: torem(185);

    @include mw($tb) {
      width: 6rem;
    }
  }

  &--footer {
    grid-area: logo;
    width: torem(185);

    @include mw($tb) {
      width: 5.5rem;
      align-self: center;
    }
  }
}
