@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins";

.title {
  display: flex;
  justify-content: center;
  &__inner {
    color: #a70e13;
    font-size: torem(72);
    font-style: italic;
    font-weight: 700;
    line-height: 138.889%;
    text-transform: uppercase;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include mw($tb) {
      font-size: 2rem;
    }

    @include mw($ph) {
      font-size: 2rem;
      padding: 0.4rem 1.3rem;
    }

    &--prizes {
      padding: 0 4rem;
      background-image: url("../../../images/prizes/title-bg.png");

      @include mw($ph) {
        background-image: url("../../../images/prizes/title-bg-mob.png");
        padding: 0.5rem 1rem;
      }
    }

    &--how {
      background-image: url("../../../images/how/title-bg-b.png");
      padding: 0 2rem;

      @include mw($tb) {
        font-size: torem(40);
        padding: 0.4rem 0.6rem;
      }

      @include mw($ph) {
        font-size: 1.9rem;
      }
    }

    &--step {
      background-image: url("../../../images/step/step-title.png");
      padding: 0 2rem;

      @include mw($tb) {
        padding: 0.5rem 2rem;
        background-image: url("../../../images/step/step-title-mob.png");
      }

      @include mw($ph) {
        padding: 0.5rem 1rem;
        background-image: url("../../../images/step/step-title-mob.png");
      }
    }

    &--winner {
      background-image: url("../../../images/step/step-title.png");
      padding: 0 2rem;

      @include mw($tb) {
      }

      @include mw($ph) {
        padding: 0.3rem 1rem;
      }
    }

    &--royal {
      background-image: url(../../../images/royal/royal-title.png);
      padding: 0 2rem;

      @include mw($tb) {
        background-image: url(../../../images/royal/royal-title-mob.png);
        font-size: 1.9rem;
        padding: 0.5rem 1.2rem;
      }
    }

    &--faq {
      background-image: url("../../../images/faq/faq-title.png");
      padding: 0 2rem;

      @include mw($ph) {
        padding: 0.3rem 1.2rem;
      }
    }

    &--feedback {
      background-image: url("../../../images/feedback/feedback-title.png");
      padding: 0 2rem;

      @include mw($tb) {
        font-size: 1.9rem;
      }

      @include mw($ph) {
        background-image: url("../../../images/feedback/feedback-title-mob.png");
        padding: 0.3rem 1.2rem;
      }
    }

    &--promo {
      background-image: url(../../../images/royal/royal-title.png);
      padding: 0 2rem;

      @include mw($ldt) {
        font-size: 3.4rem;
      }

      @include mw($dt) {
        font-size: 3rem;
      }

      @include mw($tb) {
        background-image: url(../../../images/royal/royal-title-mob.png);
        font-size: 1.9rem;
        padding: 0.5rem 1.2rem;
      }
    }

    &--myprizes {
      background-image: url("../../../images/step/step-title.png");
      padding: 0 2rem;

      @include mw($tb) {
      }

      @include mw($ph) {
        background-image: url("../../../images/step/step-title.png");
        padding: 0.3rem 1rem;
      }
    }

    &--uploaded {
      background-image: url("../../../images/how/title-bg-b.png");
      padding: 0 2rem;

      @include mw($tb) {
        font-size: torem(40);
        padding: 0.5rem 1.4rem;
      }

      @include mw($ph) {
        background-image: url(../../../images/royal/royal-title-mob.png);
        font-size: 1.9rem;
        padding: 0.5rem 1.4rem;
      }
    }

    &--quiz {
      padding: 0 2rem;
      // background-image: url("../../../images/prizes/title-bg.png");
      background-image: url("../../../images/quiz/quiz-title.png");

      @include mw($tb) {
        padding: 0.2rem 0.8rem;
      }

      @include mw($ph) {
        background-image: url("../../../images/prizes/title-bg-mob.png");
        // padding: 0.5rem 1rem;
      }
    }

    &--fail {
      background-image: url("../../../images/how/title-bg-b.png");
      padding: 0 2rem;

      @include mw($tb) {
        font-size: torem(40);
        padding: 0.4rem 1.5rem;
      }

      @include mw($ph) {
        padding: 0.4rem 1.2rem;
        font-size: 1.7rem;
      }
    }

    &--zero {
      background-image: url("../../../images/how/title-bg-b.png");
      padding: 0 2rem;
      font-size: 3rem;

      @include mw($tb) {
        font-size: 1.9rem;
        padding: 0.4rem 1.5rem;
      }

      @include mw($ph) {
        padding: 0.4rem 1.2rem;
        font-size: 1.2rem;
      }
    }

    &--over {
      background-image: url("../../../images/how/title-bg-b.png");
      padding: 0 2rem;
      font-size: 3rem;

      @include mw($tb) {
        font-size: 1.9rem;
        padding: 0.4rem 1.5rem;
      }

      @include mw($ph) {
        padding: 0.4rem 1.2rem;
        font-size: 1.1rem;
      }
    }
  }
}
