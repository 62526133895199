@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.accordeon {
  padding: torem(48) torem(72);

  @include mw($tb) {
    padding: torem(0) torem(16);
  }

  &__head {
    padding: torem(20) 0;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__head:not(:last-child) {
    border-bottom: torem(1) solid rgba(167, 14, 19, 0.24);
  }

  &__title {
    padding: 1.5rem 0;
    color: #111;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 133.333%;
    width: 90%;
    text-align: left;

    @include mw($ph) {
      padding: 0.7rem 0;
    }
  }

  &__btn {
    flex-shrink: 0;
    transform: rotate(-90deg);
  }

  &__body {
    color: #111;
    font-size: torem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    width: 90%;
  }
}

.szh-accordion__item-btn {
  width: 100%;
  background-color: transparent;
}

.szh-accordion__item-content {
  transition: height 0.5s cubic-bezier(0, 0, 0, 1);
}

.szh-accordion__item--expanded svg {
  transform: rotate(180deg);
}

.szh-accordion__item-content {
  color: #111;
  font-size: torem(24);
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  width: 90%;

  & p {
    padding: 0.5rem 0;
  }
}
