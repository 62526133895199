@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.winner {
  margin-bottom: 9rem;

  @include mw($tb) {
    margin-bottom: 4rem;
  }
  &__inner {
    padding-top: torem(132);

    @include mw($tb) {
      padding-top: 3.8rem;
    }
  }

  &__title {
    margin-bottom: 5rem;

    @include mw($tb) {
      margin-bottom: 2rem;
    }
  }

  &__content {
    display: flex;
    column-gap: 6.4%;

    @include mw($tb) {
      display: block;
      column-gap: 0;
    }
  }

  &__right {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // row-gap: torem(81);
    align-self: flex-start;
    flex-grow: 1;
    min-width: 0;
    .swiper {
      width: 100%;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 3%;
    padding-top: 3%;
    flex-shrink: 0;

    @include mw($tb) {
      padding-left: 0;
      margin-bottom: 2.8rem;
    }
  }

  &__ipad {
    width: 66%;
    div {
      border-radius: 1.1rem;
      overflow: hidden;
      display: flex;
      box-shadow: 0px 25.75207px 61.16117px 0px rgba(0, 0, 0, 0.4);

      @include mw($tb) {
        border-radius: 0;
      }
    }

    @include mw($tb) {
      width: 28%;
    }
  }

  &__prize {
    margin-top: 3rem;
    color: #fff;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 131.25%;

    @include mw($tb) {
      font-size: 1rem;
      margin-top: 1.8rem;
    }
  }

  &__period {
    color: #fff;
    font-size: torem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 116%;

    @include mw($tb) {
      font-size: 0.9rem;
    }
  }
}

.persons {
  position: relative;
  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    @include mw($tb) {
      align-items: center;
      row-gap: 0.2rem;
    }
  }

  &__date,
  &__phone {
    color: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    @include mw($tb) {
      font-size: torem(18);
    }
  }

  &__name {
    color: #fff;

    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;

    @include mw($tb) {
      font-size: torem(20);
    }
  }

  &__prev {
    display: none;

    @include mw($tb) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    position: absolute;
    top: 13%;
    left: 0;
    z-index: 1;
  }

  &__next {
    display: none;

    @include mw($tb) {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    position: absolute;
    top: 13%;
    right: 0;
    transform: rotate(180deg);
  }

  &--empty {
    align-self: center;
  }
}

.empty {
  &__text {
    color: #fff;
    font-size: torem(24);
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;

    @include mw($tb) {
      font-size: torem(20);
      padding: 0 1rem;
      text-align: center;
    }

    margin-bottom: 5rem;
  }

  &__btn {
    @include mw($tb) {
      margin: 0 auto;
    }
  }
}
