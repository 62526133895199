*,
*::before,
*::after {
  box-sizing: inherit;
  // min-height: 0;
  // min-width: 0;
}

html {
  box-sizing: border-box;
  font-size: 0.84vw;

  @include mw($ldt) {
    font-size: 1.1vw;
  }

  @include mw($dt) {
    font-size: 1.2vw;
  }

  @include mw($tb) {
    font-size: 2.66vw;
  }

  @include mw($ph) {
    font-size: 3.73vw;
  }
}

body {
  @include font("ProximaNova");
  color: #fff;
  background-color: #a70e13;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a70e13;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f5ec5a;
  }

  &.isModal {
    overflow: hidden;
    padding-right: 8px;

    @include mw($tb) {
      padding-right: 0;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
}

svg {
  // max-width: 100%;
  // max-height: 100%;
}

h1,
h2,
h3,
h4,
p,
figure,
fieldset,
ul {
  margin: 0;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

input,
button,
select,
a,
textarea,
label,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

fieldset {
  border: none;
  padding: 0;
}

button,
input,
a {
  &:focus {
    outline: none;
  }
}

button {
  border: none;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-clip: text;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: $gap;
  padding-left: $gap;
  // max-width: $width-dt + $gap * 2;
  width: 80%;

  @include mw($ldt) {
    width: 95%;
  }

  @include mw($dt) {
    width: auto;
  }
}

.border-r {
  border-right: 1px solid #121212;
}
.border-l {
  border-left: 1px solid #121212;
}

.title {
  font-size: 3.125rem;

  @include mw($ph) {
    font-size: 9.06vw;
  }
}

.p80 {
  padding: 5rem;

  @include mw($dt) {
    padding: 3.2rem;
  }

  @include mw($tb) {
    padding: 5rem;
  }

  @include mw($ph) {
    padding: 2.857rem 1.14rem;
  }
}

.subtitle {
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include mw($ph) {
    font-size: 6.93vw;
  }
}

.swiper-button-next {
  color: #121212;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #121212;

  @include mw($ph) {
    content: "";
  }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #121212;
  @include mw($ph) {
    content: "";
  }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #121212;
}

.fBbmpu {
  flex-grow: 1;
  height: 100%;
}

.swiper-pagination {
  display: none;
  @include mw($tb) {
    display: flex;
    justify-content: center;
    column-gap: torem(12);
    padding: torem(5) 0;
  }
}

.bullet {
  &__item {
    width: torem(12);
    height: torem(12);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.48);
    border: torem(2) solid transparent;
    transition: 0.5s;

    &--active {
      background-color: #a70e13;
      border: torem(2) solid #f5ec5a;
      transform: scale(1.8);
    }
  }
}

.prizes--bullets {
  margin-top: 1.7rem;
}

.how--bullets {
  margin-top: 2.8rem;
}

.persons--bullets {
  margin-top: 3.5rem;
}

.persons {
  .swiper {
    width: 100%;
    margin-left: 0;
    max-width: 91%;

    @include mw($dt) {
      max-width: 100%;
      margin-left: auto;
    }
  }

  .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3.8rem;
    column-gap: 1rem;

    @include mw($tb) {
      display: flex;
      gap: 0;
    }
  }
}

* {
  // outline: 1px solid #ff6347;
}
