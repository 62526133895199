@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.promo {
  border-bottom: torem(1) solid #f5ec5a;
  background-image: url("../../../images/step/step-elipce.png"),
    url("../../../images/royal/win-elipse.png");
  background-repeat: no-repeat;
  background-position: right bottom, left top;

  @include mw($tb) {
    background-image: url("../../../images/step/step-elipse-mob.png");
    background-position: bottom 0;
    background-size: 100% auto;
  }
  &__inner {
    display: flex;
    padding-left: 5.3%;
    padding-right: 9.5%;

    @include mw($tb) {
      padding: 0 1rem;
      flex-direction: column;
    }

    @include mw($ph) {
      padding: 0;
    }
  }

  &__left {
    width: 46%;
    padding-top: 7.5rem;
    padding-bottom: 4rem;

    @include mw($tb) {
      width: 100%;
      padding-left: 0;
      padding-top: 1.6rem;
      padding-bottom: 0;
    }
  }

  &__right {
    width: 53%;

    @include mw($tb) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 5.5rem;

    @include mw($tb) {
      margin-bottom: 2.5rem;
    }
  }

  &__stop {
    position: absolute;
    left: 33%;
    bottom: 14%;
    z-index: 1;

    @include mw($tb) {
      @include centered("x");
      bottom: 10%;
      width: 50%;
    }
  }
}

.step {
  padding-top: 2rem;
  border-bottom: 0.0625rem solid #f5ec5a;
  padding-bottom: 5rem;
  overflow: hidden;

  &__right {
    width: 54%;

    @include mw($tb) {
      width: 100%;
    }
  }
}

.machine {
  &__priv {
    @include mw($tb) {
      padding: 0 15px;
    }
  }
}

.user {
  padding: 3rem;
  background-color: #fff;
  border-radius: torem(40);
  width: 94%;

  @include mw($tb) {
    width: auto;
  }

  @include mw($ph) {
    border-radius: 0;
    padding: 2rem 1rem;
  }

  &__name {
    color: #111;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 85.938%;
    margin-bottom: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include mw($tb) {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__block {
    width: 100%;

    &--half {
      display: flex;
      column-gap: 1rem;

      @include mw($ldt) {
        flex-direction: column;
        row-gap: 1rem;
      }
    }

    &--info {
      margin-bottom: 2.4rem;

      @include mw($ph) {
        margin-bottom: 1.8rem;
      }
    }

    &--exit {
      margin-top: 1rem;
    }
  }

  &__btn {
    width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__name {
    color: #a2a2a2;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%;
  }

  &__label {
    color: #111;
    font-size: torem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 114.583%;
  }
}
