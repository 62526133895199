@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.multy {
  &--how {
    background-image: url("../../../images/how/how-elips.png"),
      url("../../../images/step/step-elipce.png");
    background-repeat: no-repeat;
    background-position: 50% 0, 100% 100%;
    background-size: 100% auto;

    @include mw($tb) {
      background-image: url("../../../images/how/how-bg-mob.png"),
        url("../../../images/step/step-elipse-mob.png");
      background-size: 100% auto, 100% auto;
      background-position: 50% 0, 50% 100%;
    }
  }
}
