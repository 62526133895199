@import "../helpers/_mixins.scss";

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: torem(24) !important;
  width: torem(24) !important;
  background-image: url("../../images/svg/menuClose.svg");
  background-repeat: no-repeat;
  background-size: cover;
  right: torem(32) !important;
  top: torem(32) !important;

  span {
    display: none;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background: #a81014;
}

/* General sidebar styles */
.bm-menu {
  background: #a81014;
  /* padding: r; */
  padding: torem(48) torem(24);
  font-size: 1.15em;
  width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  // padding: 0.8em;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
