@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.promo {
  position: relative;
  aspect-ratio: 2 / 1;
  margin-bottom: -9.7rem;
  overflow: hidden;

  &--isload {
    opacity: 0;
  }

  @include mw($ldt) {
    margin-bottom: -7.5rem;
  }

  @include mw($dt) {
    margin-bottom: -6.8rem;
  }

  @include mw($tb) {
    aspect-ratio: 1 / 1.63;
    margin-bottom: 0;
  }

  &__img-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    display: flex;
    flex-direction: column;

    .sc-aXZVg {
      flex-grow: 1;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &__content {
    position: absolute;
    @include centered("x");
    top: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mw($tb) {
      top: 160%;
    }

    @include mw($ph) {
      top: 182%;
    }

    &--end {
      top: 50%;

      @include mw($tb) {
        top: 190%;
      }

      @include mw($ph) {
        top: 190%;
      }
    }
  }

  &__btn {
    margin-top: 3rem;

    @include mw($ph) {
      margin-top: 1rem;
    }
  }

  &__maskot {
    position: absolute;
    left: 0%;
    bottom: 0%;
    aspect-ratio: 1;
    width: 40%;

    @include mw($tb) {
      display: none;
    }
  }
}

.promo-title {
  display: flex;
  justify-content: space-between;
  color: #fff;
  text-shadow: 0px 24px 57px rgba(0, 0, 0, 0.4);
  font-size: torem(86);
  font-style: italic;
  font-weight: 900;
  line-height: 1.05;
  text-transform: uppercase;
  padding-top: 4.2rem;
  padding-left: 13rem;
  padding-right: 6rem;

  @include mw($ldt) {
    font-size: torem(70);
    padding-right: 3rem;
  }

  @include mw($dt) {
    font-size: 4rem;
  }

  @include mw($tb) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 1rem;
    align-items: flex-end;
    font-size: 3rem;
  }

  @include mw($ph) {
    padding-top: 1.1rem;
    font-size: 2.3rem;
    line-height: 1.05;
  }

  &__block {
    display: flex;
    flex-direction: column;
  }
}

.title-left {
  @include mw($tb) {
    flex-direction: row;
  }
  &__top {
    padding-left: 8rem;
    letter-spacing: 0.4rem;

    @include mw($ldt) {
      padding-left: 6rem;
    }

    @include mw($tb) {
      padding-left: 0;
      margin-right: 0.8rem;
      letter-spacing: 0.16rem;
    }
  }
  &__down {
    align-self: flex-start;
    background-image: url("../../../images/svg/royal.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: transparent;
    text-shadow: none;
    padding: 0 0.5rem;

    @include mw($tb) {
      transform: translateY(-0.1rem);
    }

    @include mw($ph) {
      padding: 0;
      letter-spacing: 0.1rem;
    }
  }
}

.title-right {
  align-items: flex-end;

  &__top {
    padding-right: 0.5rem;
    // letter-spacing: -0.3rem;

    @include mw($ldt) {
      // letter-spacing: -0.1rem;
      // padding-right: 6rem;
    }

    @include mw($tb) {
      padding-right: 0;
    }
  }
}

.count {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    color: #fff;
    margin-bottom: torem(10);
    font-size: torem(20);
    font-style: italic;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    @include mw($ph) {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
  }
}

.digits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: torem(21);

  @include mw($ph) {
    column-gap: 0.9rem;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: torem(2);
  }

  &__title {
    color: #fff;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
  }

  &__num {
    color: #fff;
    flex-shrink: 0;
    font-size: torem(40);
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    padding: torem(0.5) torem(7.5);
    border-radius: torem(12);
    border: torem(2) solid #f5ec5a;
    background: #a80e13;
    box-shadow: 0px torem(8) torem(11) 0px rgba(0, 0, 0, 0.16) inset,
      0px -torem(8) torem(9) 0px rgba(0, 0, 0, 0.16) inset,
      0px torem(15) torem(56) 0px rgba(0, 0, 0, 0.16);

    @include mw($ldt) {
      font-size: torem(30);
    }

    @include mw($ph) {
      padding: 0.3rem 0.4rem;
    }
  }
}
