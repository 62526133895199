@import "../../../sass/helpers/variables";
@import "../../../sass/helpers/mixins.scss";

.uploaded {
  padding-top: 7.5rem;
  padding-bottom: 6rem;

  @include mw($ph) {
    padding-top: 2rem;
  }

  &__title {
    margin-bottom: 6rem;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;

    &--empty {
      display: block;
    }

    @include mw($tb) {
      display: flex;
      flex-direction: column;
      row-gap: torem(10);
      column-gap: 0;
    }
  }
}

.card {
  aspect-ratio: 1;
  border-radius: 2rem;
  border: torem(2) solid #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;

  @include mw($ph) {
    border-radius: 1rem;
  }

  &__inner {
    flex-grow: 1;
    position: relative;
  }

  &__btn {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;

    @include hocus() {
      .card__eye {
        background-color: #a70e13;

        svg {
          stroke: #fff;
        }
      }
    }

    &:active {
      .card__eye {
        background-color: #fff;

        svg {
          stroke: #a70e13;
        }
      }
    }
  }

  &__img-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__eye {
    position: absolute;
    @include centered();
    width: torem(80);
    height: torem(80);
    border-radius: torem(20);
    border: torem(1) solid #a70e13;
    background-color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    svg {
      stroke: #a70e13;
      width: torem(24);
      height: torem(24);
      transition: 0.3s;
    }
  }
}

.head {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  padding: torem(12) torem(20);
  display: flex;
  justify-content: space-between;
  color: #111;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: torem(1) solid rgba(0, 0, 0, 0.24);
  background: #fff;
  box-shadow: 0px -10px 48px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;

  @include mw($ph) {
    padding: torem(18) torem(16);
  }
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  cursor: pointer;
  aspect-ratio: 1;
  border-radius: 2rem;
  border: torem(2) solid #fff;
  background-color: transparent;

  @include mw($tb) {
    aspect-ratio: 5 / 1;
    flex-direction: row;
    column-gap: torem(12);
    margin-bottom: torem(28);
    border-radius: 1rem;
  }

  &--empty {
    aspect-ratio: 1 / 0.18;
    width: 100%;
  }

  &__plus {
    aspect-ratio: 1;
    width: torem(64);

    @include mw($ph) {
      width: torem(40);
    }

    svg {
      width: 100%;
      height: 100%;
      stroke: #fff;
    }
  }

  &__label {
    color: #fff;
    font-size: torem(32);
    font-style: italic;
    font-weight: 2;
    text-transform: uppercase;
  }
}
.status {
  position: absolute;
  bottom: torem(14);
  right: torem(14);
  padding: torem(4) torem(9);
  border-radius: torem(32);
  z-index: 1;
  display: flex;
  align-items: center;

  &--0 {
    background-color: #0098ee;
  }

  &--1 {
    background-color: #0098ee;
  }

  &--3 {
    background-color: #00b126;
  }
  &--4 {
    background-color: #ff4e4e;
  }
  &--5 {
    background-color: #f8a210;
  }

  &__text {
    color: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  &__info {
    margin-left: torem(8);

    svg {
      width: torem(24);
      height: torem(24);
      stroke: #fff;
      transform: translateY(torem(2));
    }
  }
}

.reason {
  position: absolute;
  @include centered();
  width: 90%;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem 0.8rem;
  z-index: 3;
  border: torem(1) solid #a70e13;

  &__wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
  }

  &__text {
    color: #111;
    font-size: 1rem;
    pointer-events: none;
  }
}
