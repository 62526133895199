@import "../../sass/helpers/variables";
@import "../../sass/helpers/mixins.scss";

.footer {
  border-top: torem(1) solid #f5ec5a;
  margin-top: auto;
  &__inner {
    width: 90%;
    margin: 0 auto;
    padding-top: torem(34);
    padding-bottom: torem(34);
    display: grid;
    grid-template-columns: 1fr 50% 1fr;
    grid-template-rows: 1.5fr 1fr;
    grid-template-areas:
      "logo doc soc"
      "promo art soc";

    @include mw($tb) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "logo  soc"
        " doc doc"
        "art art"
        " promo promo";
    }
  }

  &__soc {
    grid-area: soc;
  }

  &__doc {
    display: flex;
    column-gap: torem(72);
    justify-content: center;
    align-items: center;
    grid-area: doc;

    @include mw($tb) {
      flex-direction: column;
      padding-top: 2.5rem;
      row-gap: 1rem;
    }
  }

  &__promo {
    grid-area: promo;

    @include mw($tb) {
      display: flex;
      justify-content: center;
    }
  }

  &__art {
    grid-area: art;
    justify-self: center;
    align-self: center;
    text-align: center;

    @include mw($tb) {
      margin-top: 1rem;
    }
  }
}

.promo {
  &__text {
    color: #fff;
    font-size: torem(20);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    opacity: 0.3;
    padding: 0.5rem 0;

    @include mw($tb) {
      padding-top: 1rem;
    }
  }
}

.art__text {
  opacity: 0.3;
  color: #fff;
  font-size: torem(20);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  @include mw($tb) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.soc {
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  @include mw($tb) {
    column-gap: 0.5rem;
    align-items: flex-start;
  }

  &__item {
    aspect-ratio: 1;
    width: torem(64);
    border-radius: 1rem;
    background-color: #fff;
    position: relative;
    transition: 0.3s;
    border: torem(1) solid #fff;

    @include mw($tb) {
      width: torem(48);
      border-radius: torem(8);
    }

    svg {
      fill: #a70e13;
      position: absolute;
      @include centered();
      aspect-ratio: 1;
      width: 75%;
      transition: 0.3s;
    }

    @include hocus() {
      background-color: transparent;
      border-color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
}
